<template lang="pug">
    section.c-modals
        <modal name="constructor_form" width="820" height="auto" :adaptive="true" @before-open="beforeOpenQuestion">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('constructor_form')")
                    <svg-icon name="close"/>
                .c-guide__title ФОРМА БРИЛЛИАНТА
                .c-guide__text Огранка камней - процесс придания самородку идеально выведенных углов и граней, которые пропускают внутрь камня максимальное количество света и позволяют играть в его гранях.
                .c-guide__list
                    label.c-guide__list-item(v-for="(item, i) in $root.forms")
                        input(:id="'form'+i", type="radio", name="form", v-model="val_form", :value="item.name")
                        .c-guide__list-item__body
                            .c-guide__list-item__pic
                                <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                            .c-guide__list-item__name {{item.name}}
                .c-guide__detail
                    .c-guide__detail-pic
                        <img :src="require(`@/assets/img/content/forms/${val_pic_form}`)" alt="">
                    .c-guide__detail-body
                        .c-guide__title {{val_form}}
                        .c-guide__text {{val_text}}
        </modal>
        <modal name="constructor_weight" width="820" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('constructor_weight')")
                    <svg-icon name="close"/>
                .c-guide__title Вес
                .c-guide__text Вес камня исчисляется в каратах. Каратность важна для определения стоимости. Меньше всего ценится крошка (пыль) и мелкие ограненные алмазы до 0.3 мм. Самородки крупные (от 1 ct) или средние (0.3–0.999 ct) стоят в 15 раз больше такого же веса минералов маленького калибра.
        </modal>
        <modal name="constructor_color" width="450" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('constructor_color')")
                    <svg-icon name="close"/>
                .c-guide__title Цвет
                .c-guide__text Характеризует степень поглощения белого света внутри бриллианта.
                ul.c-guide__info
                    li.c-guide__info-item
                        .c-guide__info-pic
                            <img src="@/assets/img/content/forms/color1.png" alt="">
                        .c-guide__info-body
                            .c-guide__info-title
                                span 1(D)
                            .c-guide__info-text Идеально прозрачный
                    li.c-guide__info-item
                        .c-guide__info-pic
                            <img src="@/assets/img/content/forms/color2.png" alt="">
                        .c-guide__info-body
                            .c-guide__info-title
                                span 2(E)
                                span 3(F)
                            .c-guide__info-text С незаметным оттенком
                    li.c-guide__info-item
                        .c-guide__info-pic
                            <img src="@/assets/img/content/forms/color3.png" alt="">
                        .c-guide__info-body
                            .c-guide__info-title
                                span 4(G)
                                span 5(H)
                            .c-guide__info-text С незначительным оттенком
                    li.c-guide__info-item
                        .c-guide__info-pic
                            <img src="@/assets/img/content/forms/color4.png" alt="">
                        .c-guide__info-body
                            .c-guide__info-title
                                span 6(I)
                                span 7(J)
                            .c-guide__info-text С заметным оттенком
        </modal>
        <modal name="constructor_clear" width="450" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('constructor_clear')")
                    <svg-icon name="close"/>
                .c-guide__title Чистота
                .c-guide__text Обозначает наличие и количество вкраплений в бриллианте.
                ul.c-guide__info
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/clear1.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 1(IF)
                        .c-guide__info-text Без включений
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/clear2.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 2(VVS1)
                            span 3(VVS2)
                        .c-guide__info-text Мелкие незаметные включения
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/clear3.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 4(VS1)
                            span 5(VS2)
                        .c-guide__info-text Мелкие включения
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/clear4.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 6(SI1)
                            span 7(SI2)
                            span 7a(SI3)
                        .c-guide__info-text Мелкие заметные включения
        </modal>
        <modal name="callback" width="100%" height="100%">
            .c-modal
                .container
                    .c-header
                        button.c-hamburber(@click="$modal.hide('callback')")
                            <svg-icon name="close"/>
                        <router-link class="c-logo" to="/">
                            <img src="@/assets/img/svg-img/OnlyDiamondLogoDark2.svg" alt="">
                        </router-link>
                        .c-header__contact
                            .c-header__instagram
                              a(:href="$store.state.vk" target="_blank")
                                <svg-icon name="vk"/>
                              a(:href="$store.state.telegram" target="_blank")
                                <svg-icon name="telegram"/>
                            a.c-header__tel(:href="'tel:'+$store.state.phoneLink") {{$store.state.phone}}
                            button.c-header__callback(@click="show()")  Перезвоните мне
                    div.c-modal__body
                        <Form title="Обратный звонок" id="callback"></Form>
        </modal>
        <modal name="order" width="100%" height="100%">
            .c-modal
                .container
                    .c-header
                        button.c-hamburber(@click="$modal.hide('order')")
                            <svg-icon name="close"/>
                        <router-link class="c-logo" to="/">
                            <img src="@/assets/img/svg-img/OnlyDiamondLogoDark2.svg" alt="">
                        </router-link>
                        .c-header__contact
                            .c-header__instagram
                              a(:href="$store.state.vk" target="_blank")
                                <svg-icon name="vk"/>
                              a(:href="$store.state.telegram" target="_blank")
                                <svg-icon name="telegram"/>
                            a.c-header__tel(:href="'tel:'+$store.state.phoneLink") {{$store.state.phone}}
                            button.c-header__callback(@click="show()")  Перезвоните мне
                    div.c-modal__body
                        <Form title="Заявка на бриллиант"/>
        </modal>
        <modal name="constructor" width="100%" height="100%" @before-open="beforeOpen">
            .c-modal
                .container
                    .c-header
                        button.c-hamburber(@click="$modal.hide('constructor')")
                            <svg-icon name="close"/>
                        <router-link class="c-logo" to="/">
                            <img src="@/assets/img/svg-img/OnlyDiamondLogoDark2.svg" alt="">
                        </router-link>
                        .c-header__contact
                            .c-header__instagram
                              a(:href="$store.state.vk" target="_blank")
                                <svg-icon name="vk"/>
                              a(:href="$store.state.telegram" target="_blank")
                                <svg-icon name="telegram"/>
                            a.c-header__tel(:href="'tel:'+$store.state.phoneLink") {{$store.state.phone}}
                            button.c-header__callback(@click="show()")  Перезвоните мне
                    div.c-modal__body.c-modal__body--constructor
                      <Form title="Заказать бриллиант" id="constructor" :val_form="val_form" :weight="weight" :val_color="val_color" :val_clear="val_clear" :val_price="val_price" :val_pic="val_pic" :currency="currency"/>
        </modal>
        <modal name="consult" width="100%" height="100%">
            .c-modal
                .container
                    .c-header
                        button.c-hamburber(@click="$modal.hide('consult')")
                            <svg-icon name="close"/>
                        <router-link class="c-logo" to="/">
                            <img src="@/assets/img/svg-img/OnlyDiamondLogoDark2.svg" alt="">
                        </router-link>
                        .c-header__contact
                            .c-header__instagram
                              a(:href="$store.state.vk" target="_blank")
                                <svg-icon name="vk"/>
                              a(:href="$store.state.telegram" target="_blank")
                                <svg-icon name="telegram"/>
                            a.c-header__tel(:href="'tel:'+$store.state.phoneLink") {{$store.state.phone}}
                            button.c-header__callback(@click="show()")  Перезвоните мне
                    div.c-modal__body.c-modal__body--constructor
                      <Form id="consult" title="Консультация по выбору"/>
        </modal>
        <modal name="constructor-order" width="520" height="auto" :adaptive="true" @before-open="beforeOpen">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('constructor-order')")
                  <svg-icon name="close"/>
                <Form title="ДАННАЯ ФОРМА<br> ДОСТУПНА ПОД ЗАКАЗ" id="constructor-order" :val_form="val_form" sub_title="Оставьте номер и мы свяжемся с вами"></Form>
        </modal>
        <modal name="userAgreement" width="820" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('userAgreement')")
                    <svg-icon name="close"/>
                .c-guide__title Пользовательское Соглашение
                .c-guide__text
                    p Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ООО "Рубин" (далее onlydiamond.ru/ или Администрация) с одной стороны и пользователем сайта с другой.
                    p Сайт onlydiamond.ru/ не является средством массовой информации.
                    p Используя сайт, Вы соглашаетесь с условиями данного соглашения.
                    p Если Вы не согласны с условиями данного соглашения, не используйте сайт onlydiamond.ru/!
                    h3 Права и обязанности сторон
                    b Пользователь имеет право:
                    ul
                      li осуществлять поиск информации на сайте
                      li получать информацию на сайте
                      li использовать информацию сайта в личных некоммерческих целях
                    b Администрация имеет право:
                    p по своему усмотрению и необходимости создавать, изменять, отменять правила ограничивать доступ к любой информации на сайте
                    b Пользователь обязуется:
                    ul
                      li не нарушать работоспособность сайта
                      li не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами
                    b Администрация обязуется:
                    ul
                      li поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.
                    h3 Ответственность сторон
                    ul
                      li администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
                      li в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса
                    h3 Условия действия Соглашения
                    p Данное Соглашение вступает в силу при любом использовании данного сайта.
                    p Соглашение действует бессрочно.
                    p Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
                    p Администрация не оповещает пользователей об изменении в Соглашении.
        </modal>
        <modal name="agreement" width="820" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('agreement')")
                    <svg-icon name="close"/>
                .c-guide__title Соглашение на обработку персональных данных
                .c-guide__text
                    p Компания ООО "РУБИН" гарантирует конфиденциальность получаемой информации. Обработка персональных данных осуществляется в целях эффективного исполнения заказов, договоров и иных обязательств, принятых компанией ООО "РУБИН" в качестве обязательных к исполнению.
                    p Настоящее согласие распространяется на следующие Ваши персональные данные: фамилия, имя и отчество, адрес электронной почты, почтовый адрес доставки заказов, контактный телефон, платёжные реквизиты.
                    p Срок действия согласия является неограниченным. Вы можете в любой момент отозвать настоящее согласие, направив письменное уведомления на адрес: 354340, Краснодарский край, г.Сочи, Ереванский пер, д. 8, кв. 4, ПОМЕЩЕНИЕ №443 с пометкой «Отзыв согласия на обработку персональных данных».
                    p Гарантирую, что представленная мной информация является полной, точной и достоверной, а также что при представлении информации не нарушаются действующее законодательство Российской Федерации, законные права и интересы третьих лиц. Вся представленная информация заполнена мною в отношении себя лично.
                    p Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не предусмотрено законодательством Российской Федерации.
        </modal>
        <modal name="delivery" width="400" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('delivery')")
                    <svg-icon name="close"/>
                .c-guide__title Доставка и оплата
                .c-guide__text
                    p Самовывоз и курьерская доставка.
                    p Оплата любым удобным способом.
        </modal>
        <modal name="success" width="450" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('success')")
                    <svg-icon name="close"/>
                .c-guide__title Заявка успешно отправлена
                .c-guide__text Менеджер свяжется с вам в ближайшее время
        </modal>
        <modal name="catalog_pic" width="500" height="auto" :adaptive="true" @before-open="beforeOpenPic">
            .c-modal__info.c-modal__info--pic
                button.c-hamburber(@click="$modal.hide('catalog_pic')")
                    <svg-icon name="close"/>
                <img v-if="product_pic" :src="require(`@/assets/img/content/products/${product_pic}`)"/>
        </modal>
        <modal name="requisites" width="400" height="auto" :adaptive="true">
            .c-modal__info
                button.c-hamburber(@click="$modal.hide('requisites')")
                    <svg-icon name="close"/>
                .c-guide__title Реквизиты
                .c-guide__text
                  p ОГРН 1192375082778
                  p ИНН 2367011900
                  p ООО «Рубин»
        </modal>
</template>

<script>
import Form from '@/components/Form'

export default {
  name: 'empty-layout',
  components: {
    Form,
  },
  data() {
    return {
      weight: 0.1,
      min_weight: 0.1,
      max_weight: 5.0,
      val_form: this.$root.forms[0].name,
      val_color: this.$root.colors[0].name,
      val_clear: this.$root.clears[0].name,
      val_price: 302000,
      val_pic: '',
      currency: '',
      product_pic: null,
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    val_pic_form: function() {
      var result = this.$root.forms.filter(x => {
        return x.name == this.val_form
      })
      if (result.length) {
        return result[0].img
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    val_text: function() {
      var result = this.$root.forms.filter(x => {
        return x.name == this.val_form
      })
      if (result.length) {
        return result[0].text
      }
    },
  },
  methods: {
    show() {
      this.$modal.hide('constructor')
      this.$modal.hide('order')
      this.$modal.hide('consult')
      this.$modal.show('callback')
    },
    beforeOpen(event) {
      this.val_form = event.params.val_form
      this.weight = event.params.weight
      this.val_color = event.params.val_color || '-'
      this.val_clear = event.params.val_clear || '-'
      this.val_price = event.params.val_price
      this.val_pic = event.params.val_pic
      this.currency = event.params.currency
    },
    beforeOpenPic(event) {
      this.product_pic = event.params.product_pic
    },
    beforeOpenQuestion(event) {
      this.val_form = event.params.form;
    },
  },
}
</script>
