<template lang="pug">
  include ../mixins/_mixins.pug
  header.header
    .container
      .c-header
        button.c-hamburber(@click="$root.showMenu = true")
          <svg-icon name="menu"/>
        <router-link class="c-logo" to="/">
        <picture>
          <source  media="(max-width: 1199px)" srcset="@/assets/img/svg-img/OnlyDiamondLogo2.svg">
          <img src="@/assets/img/svg-img/OnlyDiamondLogo1.svg" alt="">
        </picture>
        </router-link>
        .c-header__contact
          .c-header__instagram
            a(:href="$store.state.vk" target="_blank")
              <svg-icon name="vk"/>
            a(:href="$store.state.telegram" target="_blank")
              <svg-icon name="telegram"/>
          a.c-header__tel(:href="'tel:'+$store.state.phoneLink") {{$store.state.phone}}
          span.c-header__callback(@click='$modal.show("callback")') Перезвоните мне
</template>

<script>
export default {
  //name: "header",
  comments: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="sass" scoped></style>
