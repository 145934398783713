<template lang="pug">
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import Header from '@/components/Header'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('catalog', {
      currencyUsd: state => state.currencyUsd,
      ready: state => state.ready,
    }),
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
  },
  components: {
    Header,
    EmptyLayout,
  },
  async created() {
    //if (!this.ready) {
    //await this.$store.dispatch('catalog/getCurrency')
    //}
  },
}
</script>

<style lang="sass">
@import "~normalize.css"
@import "~@/assets/sass/main"
</style>
