<template lang="pug">
  .c-menu(v-if="showMenu")
    .container
      .c-modal
        .c-header
            button.c-hamburber(@click="$root.menuClose()")
                <svg-icon name="close"/>
            <router-link class="c-logo" to="/">
                <img src="@/assets/img/svg-img/OnlyDiamondLogoDark2.svg" alt="">
            </router-link>
            .c-header__contact
                .c-header__instagram
                  a(:href="$store.state.vk" target="_blank")
                    <svg-icon name="vk"/>
                  a(:href="$store.state.telegram" target="_blank")
                    <svg-icon name="telegram"/>
                a.c-header__tel(:href="'tel:'+$store.state.phoneLink") {{$store.state.phone}}
                span.c-header__callback(@click="$root.menuClose(), $modal.show('callback')") Перезвоните мне
      nav.c-menu__inner
        ul.c-menu__list
          <router-link @click.native="$root.menuClose(), reloadPage(link.url)" v-for="link in links" :key="link.url" tag="li" class="c-menu__list-item" active-class="is-active" :to="link.url" :exact="link.exact">
            span.c-menu__list-link {{link.title}}
          </router-link>
          li.c-menu__list-item(@click="$root.menuClose(), $modal.show('order')")
            span.c-menu__list-link Оставить заявку
        .c-menu__info
          .c-menu__slogan
            div СОЗДАЙТЕ
            div СВОЙ
            div СОБСТВЕННЫЙ
            div УНИКАЛЬНЫЙ
            div БРИЛЛИАНТ!
          button.c-menu__consult(@click="$root.menuClose(), $modal.show('consult')")
            <svg-icon name="consult"/>
            span Консультация
</template>

<script>
export default {
  props: ['showMenu'],
  data() {
    return {
      links: [
        { title: 'КОНСТРУКТОР', url: '/constructor/' },
        { title: 'Бриллианты', url: '/brillianty/kruglyj/' },
        { title: 'ГИД ПО ВЫБОРУ', url: '/guide/' },
        //{ title: 'КАТАЛОГ', url: '/catalog/' },
        { title: 'ВИДЕО', url: '/video/' },
        { title: 'КОНТАКТЫ', url: '/about/' },
      ],
    }
  },
  methods: {
    reloadPage(url){
      if(url === '/brillianty/kruglyj/'){
        window.location.href = url;
      }
    }
  },
}
</script>
