<template lang="pug">
    .c-navigation
        .container
            ul.c-navigation__list
                <router-link v-for="link in links" :key="link.url" tag="li" class="c-navigation__list-item" active-class="is-active" :to="link.url" :exact="link.exact" @click="console.log(1)">
                    div(@click="$root.choseTemplateView = ''")
                      <template v-if="link.icon">
                          <svg-icon :name="link.icon" class="icon__color-accent"/>
                      </template>
                      <template v-if="link.img">
                          <div class="c-navigation__list-img">
                              <img :src="require(`@/assets/img/svg-img/${link.img}`)" alt="">
                          </div>
                      </template>
                      span.c-navigation__list-link {{link.title}}
                </router-link>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { title: 'Конструктор', url: '/constructor/', icon: 'constr' },
        { title: 'Гид', url: '/guide/', icon: 'guide-icon' },
        { title: '', url: '/', img: 'OnlyDiamondLogo1.svg' },
        { title: 'Контакты', url: '/about/', icon: 'cont' },
        //{ title: 'Каталог', url: '/catalog/', icon: 'catal' },
        { title: 'Видео', url: '/video/', icon: 'video' },
      ],
    }
  },
}
</script>
